.br_dropzone {
  position: relative;
  border: 1px solid rgba(#000, 0.1);
  width: 100%;
  height: 256px;
  display: block;
  border-radius: 4px;
  box-sizing: border-box;

  background-image: linear-gradient(135deg,rgba(0,0,0,.03)25%, transparent 25%, transparent 50%, rgba(0,0,0,.03)50%, rgba(0,0,0,.03)75%, transparent 75%, transparent);
  background-color: #FAFCFD;
  background-size: 16px 16px;
}

.br_dropzone input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  font-size: 2rem;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.br_dropzone input[type=text] {
  background: none;
  border: none;
  padding: 0.5em;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2rem;
  box-sizing: border-box;
}

.br_dropzone.dragover {
  background-color: #eee;
  border: 6px dashed rgba(#000, 0.1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  margin-top: -40px !important;
}

.dropzone .dz-preview .dz-error-message {
  opacity: 1 !important;
  margin-top: -40px !important;
}

.vue-dropzone>.dz-preview .dz-remove {
  left: 10px !important;
  right: 10px !important;
  margin-left: 0 !important;
}