.multiselect__tag {
  background: none;
  color: #35495e;
  padding: 5px 21px 4px 10px;
  margin-right: 0px;
}

.multiselect__tag-icon:after {
  color: #387cfe;
}

.selected.multiselect__option--highlight {
  background: #0085ff;
  color: #fff;
}

.multiselect__option--highlight {
  background: #0085ff;
  color: #fff;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #0085ff;
  color: #0085ff;
}

.multiselect__option--highlight span{
  background: #0085ff;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #0085ff;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #0085ff;
  color: #0085ff;
}