h1 {
  &.page-header-title {
    color: #002B5E !important;
  }
}

h2, h3, h4 {
  color: #002B5E !important;
}

.page-header {
  padding-bottom:.75rem;
}

.alert {
  ul {
    list-style-type:none;
    padding:0px;
    margin:0px;
  }
}