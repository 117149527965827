.text-gas {
  color: #ff8200;
}
.text-power {
  color: #002b5e;
}
.first_uppercase {
  text-transform: capitalize !important;
}

select, select option {
  text-transform:capitalize
}

label {
  margin-bottom: 2px;
}