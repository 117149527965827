#tribute_comments a{
  color: #387dff !important;
}

.comment_text {
  color: #1d2123;
}

.tribute-container li.highlight {
  background: #387dff !important;
  color: #fff!important;
}

.tribute-container li {
  background: #fff;
}

.tribute-container ul {
  background: #fff !important;
  padding: 5px;
}

.tribute-container {
  border: 0.6px solid black;
  border-radius: 4px;
}