.navbar-vertical-aside .navbar-brand-wrapper {
  display: flex;
  align-items: center;
  height: 4.75rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

#accountNavbarDropdown {
  .btn-link {
    color: #1e2022;
  }
}