.navbar-brand-logo {
  width: 100%;
  margin-top: 7px;
  min-height: 3.4rem;
  min-width: 11.5rem;
  max-width: 6.5rem;
}

.auth-brand-logo {
  width: 100%;
  min-width: 14.5rem;
  max-width: 10.5rem;
  min-height: 3.8rem;
}

.small-navbar-brand-logo {
  width: 100%;
  margin-top: 7px;
  min-height: 2.4rem;
  min-width: 7.5rem;
  max-width: 6.5rem;
}