@import "./admin.theme.config";

@import "./pagination/pagination";
@import "./anchor/anchor";
@import "./button/button";
@import "./alert/alert";
@import "./icon/icon";
@import "./position/position";
@import "./text/text";
@import "./images/logo/logo";
@import "./images/logo/logo";
@import "./navbar/navbar";
@import "./card/card";
@import "./dropdown/dropdown";
@import "./toast/toast";
@import "./calendar/calendar";
@import "./dropzone/dropzone";
@import "./multiselect/multiselect";
@import "./comments/comments";
@import "./upload/file_upload";



@import "../../../node_modules/select2/dist/css/select2.min.css";
@import "../../../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../../../node_modules/jquery-toast-plugin/dist/jquery.toast.min.css";
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "../../../node_modules/tributejs/dist/tribute.css";

@import "./custom";